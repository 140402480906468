import React from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import AdminLayout from '../Layouts/AdminLayout';
import UseLayout from '../Layouts/UserLayout';

const AuthGuard = ({ allowedRoles }) => {
  const location = useLocation();

  // Safely parse user data from localStorage
  const storedUser = localStorage.getItem("user");
  const user = storedUser ? JSON.parse(storedUser) : null;

  // Safeguard against null or undefined role
  const roles = user?.roleName || []; // Assume roleName is an array
  const hasAllowedRole = roles.some(role => allowedRoles.includes(role));

  // Determine the layout based on roles
  const layout = roles.includes("admin") ? <AdminLayout /> : <UseLayout />;

  // Render based on user roles and permissions
  if (hasAllowedRole) {
    return layout;
  }

  // Redirect unauthorized users
  return (
    <Navigate
      to="/Un-Authorized"
      state={{ from: location }}
      replace
    />
  );
};

export default AuthGuard;
